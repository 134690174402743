<template>
  <thead class="table-header">
    <tr class="table-header__row">
      <th
        v-for="(column, i) in columns"
        :key="column.key ?? 'null' + i"
        class="header-cell"
        :class="{ 'header-cell--with-vertical-borders': withVerticalBorders }"
      >
        <div
          v-if="column.key"
          class="header-cell__content"
          :class="[
            column.isTextCentered
              ? 'header-cell__content--centered'
              : 'header-cell__content--aligned-left'
          ]"
          :style="`text-align:${column.isTextCentered ? 'center' : 'left'}`"
        >
          <span
            class="content__value"
            :class="[
              sortingByColumns[column.key]
                ? 'content__value--selected'
                : 'content__value--not-selected'
            ]"
            >{{
              column.countDistinct
                ? `${column.value} (${column.countDistinct})`
                : `${column.value}`
            }}
            <i
              v-if="column.isSortable && column.isTextCentered"
              class="value__icon"
              :class="[
                sortingByColumns[column.key] === OrderValue.DESC
                  ? 'icon-reverse-alphabet'
                  : 'icon-alphabet',
                sortingByColumns[column.key]
                  ? 'value__icon--selected'
                  : 'value__icon--not-selected'
              ]"
              @click="updateColumnsSorting(column.key)"
            />
          </span>
          <i
            v-if="column.isSortable && !column.isTextCentered"
            class="content__icon"
            :class="[
              sortingByColumns[column.key] === OrderValue.DESC
                ? 'icon-reverse-alphabet'
                : 'icon-alphabet',
              sortingByColumns[column.key]
                ? 'content__icon--selected'
                : 'content__icon--not-selected'
            ]"
            @click="updateColumnsSorting(column.key)"
          />
        </div>
      </th>
    </tr>
  </thead>
</template>

<script lang="ts">
import type { SortingByColumn } from '~/types/analytics'
import { OrderValue } from '~/types/cube'
import { type TableColumn } from '~/types/table'

export default {
  name: 'TableHeader',
  props: {
    columns: {
      type: Array as PropType<TableColumn[]>,
      required: true
    },
    sortingByColumns: {
      type: Object as PropType<SortingByColumn>,
      required: true
    },
    withVerticalBorders: {
      type: Boolean,
      default: true
    }
  },
  emits: ['updateColumnsSorting'],
  setup() {
    return { OrderValue }
  },
  methods: {
    updateColumnsSorting(column: string) {
      this.$emit('updateColumnsSorting', column)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  &__row {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $bg-quinary;
    color: $text-inverse;
  }
}

.header-cell {
  padding: $margin-small $margin-regular;
  vertical-align: middle;
  text-align: left;
  border-bottom: 1px solid $bg-primary;
  box-sizing: border-box;

  &--with-vertical-borders {
    border: solid $bg-primary;
    border-width: 0 0 1px 1px;
  }

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  &__content {
    &--centered {
      text-align: center;
    }

    &--aligned-left {
      text-align: left;
      display: flex;
      align-items: center;
    }

    .content {
      &__value {
        position: relative;
        display: inline-block;
        color: $text-primary;
        @include font-size($font-size-small);

        &--selected {
          @include font-text($font-weight-medium);
        }

        &--not-selected {
          @include font-text($font-weight-book);
        }

        .value {
          &__icon {
            position: absolute;
            left: calc(100% + 4px);
            top: 50%;
            transform: translateY(-50%);
            @include font-icon;
            @include font-size(19px);
            font-weight: $font-weight-book;
            cursor: pointer;

            &--selected {
              color: $text-primary;
            }

            &--not-selected {
              color: $text-tertiary;
            }
          }
        }
      }

      &__icon {
        @include font-icon;
        @include font-size(19px);
        font-weight: $font-weight-book;
        cursor: pointer;
        margin-left: 4px;

        &--selected {
          color: $text-primary;
        }

        &--not-selected {
          color: $text-tertiary;
        }
      }
    }
  }
}
</style>
